// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

// Extend the global interface

import { initCoralogix, logInfo } from '@entrycall/coralogix';
import twStyles from './styles/tailwind.css';
import { Provider } from 'react-redux';
import { store } from '@entrycall/redux';
import serialize from 'serialize-javascript';
import { LiveReload, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { paymentRootLoader } from '@entrycall/parking-payment-remix-server';
import { useEffect, useMemo } from 'react';
import { AuthProvider } from '@descope/react-sdk';
import { ErrorBoundaryRootPayment, EntryCallHtmlDocument, metaData, linksEntryCall } from '@entrycall/route-root';
import { sharedDOMPurify } from '@entrycall/utility-dompurify';

// Create or get a global TrustedHTML Policy
const getTrustedHTMLPolicy = () => {
  if (typeof window !== 'undefined' && window.trustedTypes) {
    try {
      // Check if the policy already exists
      const existingPolicy = window.trustedTypes.getPolicy('entrycallPolicy');
      if (existingPolicy) {
        return existingPolicy;
      }

      // Create a new policy
      return window.trustedTypes.createPolicy('entrycallPolicy', {
        createHTML: input => {
          // Sanitize HTML input using DOMPurify or a similar library
          return sharedDOMPurify.sanitize(input);
        },
        createScript: () => {
          throw new Error('Scripts are not allowed');
        },
        createScriptURL: () => {
          throw new Error('Script URLs are not allowed');
        }
      });
    } catch (error) {
      console.warn('Trusted Types policy creation failed:', error);
    }
  }
  return undefined;
};
const DESCOPE_PROJECT_ID = 'P2gbxFS9y61jkSPQ8us5qtixoBoL';

// Define the children array
const children = [{
  rel: 'stylesheet',
  href: twStyles
}, {
  href: '/icons/favicon-32x32.png',
  sizes: '32x32'
}, {
  href: '/icons/favicon-16x16.png',
  sizes: '16x16'
}, {
  href: '/assets/png/logo192.png',
  sizes: '192x192'
}, {
  href: '/assets/png/logo512.png',
  sizes: '512x512'
}, {
  primaryColor: '#ff0000',
  secondaryColor: '#00ff00'
}];

// Use the linksEntryCall function with the children array
export const links = () => linksEntryCall(children);
export const loader = paymentRootLoader;
export const meta = metaData;
export const ErrorBoundary = ErrorBoundaryRootPayment;
export default function App() {
  _s();
  const loaderData = useLoaderData();
  const envData = loaderData?.ENV ?? {};

  // Ensure envData does not contain sensitive information
  // If it does, remove or obfuscate it before serialization
  const serializedEnvData = useMemo(() => serialize(envData, {
    isJSON: true
  }), [envData]);
  const scriptContent = `window.ENV = ${serializedEnvData};`;
  useEffect(() => {
    const themeLink = document.querySelector('link[href="/styles/theme.css"]');
    if (themeLink) {
      const primary = themeLink.getAttribute('data-primary');
      const secondary = themeLink.getAttribute('data-secondary');
      if (primary) document.documentElement.style.setProperty('--primary-color', primary);
      if (secondary) document.documentElement.style.setProperty('--secondary-color', secondary);
    }
  }, []);
  useEffect(() => {
    const initApp = async () => {
      try {
        await initCoralogix();
        await logInfo('Application initialized', {
          env: envData
        });
      } catch (error) {
        console.error('Error initializing application:', error);
      }
    };
    initApp();
  }, [envData]);
  return <EntryCallHtmlDocument>
      <Provider store={store}>
        <AuthProvider projectId={DESCOPE_PROJECT_ID}>
          <Outlet />
        </AuthProvider>
        <ScrollRestoration />
        <SafeScript content={scriptContent} />
        <Scripts />
        <LiveReload />
      </Provider>
    </EntryCallHtmlDocument>;
}
_s(App, "+vzeLIt4LjL9F2LtnvEnFP+Lnoc=", false, function () {
  return [useLoaderData];
});
_c = App;
function SafeScript({
  content
}) {
  const trustedTypesPolicy = getTrustedHTMLPolicy();
  let trustedContent = content;
  if (trustedTypesPolicy) {
    try {
      trustedContent = trustedTypesPolicy.createHTML(content);
    } catch (error) {
      console.error('Failed to create trusted HTML:', error);
    }
  }
  return <script dangerouslySetInnerHTML={{
    __html: trustedContent
  }} />;
}
_c2 = SafeScript;
var _c, _c2;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "SafeScript");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;